// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import type { Node } from 'react'

import Modal from '../Modal'
import { useOverflow } from '../../hooks'

import ModalHead from '../Modal/ModalHead'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalBody from '../Modal/ModalBody'
import ModalButtons from '../Modal/ModalButtons'
import Button from '../Button'
import ModalRow from '../Modal/ModalRow/ModalRow'
import ModalCell from '../Modal/ModalCell'
import ModalLabel from '../Modal/ModalLabel'
import ModalError from '../Modal/ModalError'
import Warning from '../Warning'

import { updateOutboundConfigProfile } from '../../core/api/api.idwell2idwell'

import styles from './ConnectedCompanies.module.scss'

type Props = {
  isOpen: boolean,
  onClose: (?boolean) => void,
  uuid: string,
}

const AddKeyModal = (props: Props): Node => {
  const { isOpen, uuid } = props

  const { t } = useTranslation('IdwellToIdwell')

  const [loading, setLoading] = useState(false)
  const [key, setKey] = useState('')
  const [error, setError] = useState(null)

  useOverflow()

  const handleChangeKey = e => setKey(e.target.value)

  const handleClose = () => {
    props.onClose()
  }

  const handleAddKey = () => {
    setError(null)
    setLoading(true)
    updateOutboundConfigProfile(uuid, { key })
      .then(() => {
        props.onClose(true)
      })
      .catch(err => {
        setError(err.message?.response?.data?.errors.key)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <ModalHead title={t('AddCompanyModalTitle')} />
      <ModalCloseButton onClose={handleClose} />
      <ModalBody style={{ marginBottom: 0 }}>
        <ModalRow>
          <ModalCell>
            <ModalLabel>{t('AccessKey')}</ModalLabel>
          </ModalCell>
          <ModalCell gridNumber={2}>
            <Textarea
              className={styles.textarea}
              name={name}
              value={key}
              placeholder={t('AccessKeyPlaceholder')}
              minRows={3}
              maxRows={5}
              maxLength={256}
              onChange={handleChangeKey}
            />
          </ModalCell>
        </ModalRow>
        <ModalRow>
          <Warning
            noArrow
            text={t('HideKeyNotice')}
            boxClassName={styles.warningBox}
            className={styles.warning}
          />
        </ModalRow>
        {error && (
          <ModalRow>
            <ModalError error={t(`Errors:${error}`)} />
          </ModalRow>
        )}
        <ModalButtons>
          <Button.Save
            working={loading}
            disabled={loading || !key}
            onClick={handleAddKey}
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={loading} onClick={handleClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </ModalBody>
    </Modal>
  )
}

export default AddKeyModal
